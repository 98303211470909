import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useState } from 'react';
import { getPOSSolutions, Solution } from './posSolutions';

function App() {
  let [formTotal, setFormTotal] = useState(10000)
  let [formAmortizeYears, setFormAmortizeYears] = useState(5)
  let [solutions, setSolutions]: [Solution[], any] = useState([])

  let [sortBy, setSortBy] = useState('amortized')

  const formatterEUR = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });

  const getAmortizedCost = function (firstYearCost: number, yearlyCost: number, amortizeYears: number) {
    return (firstYearCost + yearlyCost * (amortizeYears - 1)) / amortizeYears
  }

  useEffect(() => {
    const sortSolutions = function (a: any, b: any) {
      if (sortBy === 'yearly') return a.yearlyCost - b.yearlyCost
      if (sortBy === 'amortized')
        return getAmortizedCost(a.firstYearCost, a.yearlyCost, formAmortizeYears)
          - getAmortizedCost(b.firstYearCost, b.yearlyCost, formAmortizeYears)

      return 0
    }

    const data = {
      total: formTotal
    }
    setSolutions(getPOSSolutions(data).sort(sortSolutions))
  }, [formTotal, formAmortizeYears, sortBy])

  return (
    <div className="App">
      <header className="App-header">
        <h1>
          TrovaPOS
        </h1>
      </header>
      <div className='container'>
        <div className='alert alert-info'>
          TrovaPOS è il servizio che ti permette di trovare il POS migliore per la tua attività! Inserisci qui sotto l'importo che pensi di incassare tramite POS in un anno e trova subito il POS che fa per te, confrontando costi e servizi offerti.<br />
          Tutti i POS inclusi nella lista sono indipendenti dalle banche (quindi possono essere utilizzati con qualsiasi conto corrente) e sono compatibili con i pagamenti contactless (NFC).
        </div>



        <div className='mb-3'>
          <div style={{ maxWidth: '400px' }}>
            <label>Quanto prevedi di incassare con il POS in un anno?</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">€</span>
              </div>
              <input type="number" className="form-control" defaultValue={10000} min={0} onChange={(e) => setFormTotal(isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value))} />
              <div className="input-group-append">
                <button className='btn btn-primary'>Trova</button>
              </div>
            </div>

            <div className="form-group">
              <label>Ammortizza costo iniziale su:</label>
              <div className="input-group">
                <input type="number" className="form-control" defaultValue={5} min={1} onChange={(e) => setFormAmortizeYears(isNaN(parseFloat(e.target.value)) ? 5 : parseFloat(e.target.value))} />
                <div className="input-group-append">
                  <span className="input-group-text">anni</span>
                </div>
              </div>
            </div>
          </div>

          <div className='text-center mt-3'>
            <span className='me-2'>Ordina per:</span>
            <div className="btn-group" role="group">
              <button type="button" className={(sortBy === 'amortized') ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={() => setSortBy('amortized')}>Costo ammortizzato</button>
              <button type="button" className={(sortBy === 'yearly') ? 'btn btn-primary' : 'btn btn-outline-primary'} onClick={() => setSortBy('yearly')}>Costo annuo</button>
            </div>
          </div>
        </div>

        {
          (formTotal > 500000) ? <div className='alert alert-info'>
            Per volumi elevati si consiglia di contattare direttamente gli istituti in modo da ottenere contratti pi&ugrave; convenienti.
          </div> : <></>
        }

        {
          solutions.map(solution =>
            <div className="card mb-2" key={JSON.stringify(solution)}>
              <div className="card-header">
                {solution.name}
              </div>
              <div className="card-body row">
                <div className='col col-12 col-sm-4 col-md-3 text-center'>
                  <p>
                    {(solution.features.wifi) ? <span className="badge rounded-pill bg-primary mx-1">Wi-Fi</span> : <></>}
                    {(solution.features.bluetooth) ? <span className="badge rounded-pill bg-primary mx-1">Bluetooth</span> : <></>}
                    {(solution.features.sim) ? <span className="badge rounded-pill bg-primary mx-1">SIM</span> : <></>}
                  </p>
                  <img src={solution.image} alt='Foto POS' className='posImage' />
                </div>
                <div className='col'>
                  Commissioni su {formatterEUR.format(formTotal)} / anno:
                  <ul>
                    <li>
                      Ammortizzate su {formAmortizeYears} anni: {formatterEUR.format(
                        getAmortizedCost(solution.firstYearCost, solution.yearlyCost, formAmortizeYears)
                      )} / anno {formTotal > 0 ? <>({Math.round(getAmortizedCost(solution.firstYearCost, solution.yearlyCost, formAmortizeYears) / formTotal * 100 * 100) / 100}%)</> : <></>}
                    </li>
                    <li>
                      Primo anno: {formatterEUR.format(solution.firstYearCost)} {formTotal > 0 ? <>({Math.round(solution.firstYearCost / formTotal * 100 * 100) / 100}%)</> : <></>}
                    </li>
                    <li>
                      Anni successivi: {formatterEUR.format(solution.yearlyCost)} / anno {formTotal > 0 ? <>({Math.round(solution.yearlyCost / formTotal * 100 * 100) / 100}%)</> : <></>}
                    </li>
                  </ul>

                  {solution.description ? <>
                    Condizioni:
                    <div dangerouslySetInnerHTML={{ __html: solution.description ?? '' }} />
                  </>
                    : <></>}

                  <p>
                    <img src='https://www.nexi.it/content/dam/mos/icons/payment/color/Contactless.svg' className='interbankNetworkLogo' />

                    {(solution.features.interbankNetworks.pagobancomat) ? <img src='https://www.nexi.it/content/dam/mos/icons/payment/color/PagoBancomat.svg' className='interbankNetworkLogo' /> : <></>}

                    {(solution.features.interbankNetworks.mastercard) ? <img src='https://raw.githubusercontent.com/activemerchant/payment_icons/master/app/assets/images/payment_icons/master.svg' className='interbankNetworkLogo' /> : <></>}

                    {(solution.features.interbankNetworks.maestro) ? <img src='https://raw.githubusercontent.com/activemerchant/payment_icons/master/app/assets/images/payment_icons/maestro.svg' className='interbankNetworkLogo' /> : <></>}

                    {(solution.features.interbankNetworks.visa) ? <img src='https://raw.githubusercontent.com/activemerchant/payment_icons/master/app/assets/images/payment_icons/visa.svg' className='interbankNetworkLogo' /> : <></>}

                    {(solution.features.interbankNetworks.vpay) ? <img src='https://raw.githubusercontent.com/activemerchant/payment_icons/master/app/assets/images/payment_icons/v_pay.svg' className='interbankNetworkLogo' /> : <></>}

                    {(solution.features.interbankNetworks.american_express) ? <img src='https://raw.githubusercontent.com/activemerchant/payment_icons/master/app/assets/images/payment_icons/american_express.svg' className='interbankNetworkLogo' /> : <></>}

                    {(solution.features.interbankNetworks.jcb) ? <img src='https://raw.githubusercontent.com/activemerchant/payment_icons/master/app/assets/images/payment_icons/jcb.svg' className='interbankNetworkLogo' /> : <></>}

                    {(solution.features.interbankNetworks.unionpay) ? <img src='https://raw.githubusercontent.com/activemerchant/payment_icons/master/app/assets/images/payment_icons/unionpay.svg' className='interbankNetworkLogo' /> : <></>}

                    {(solution.features.interbankNetworks.diners_club) ? <img src='https://raw.githubusercontent.com/activemerchant/payment_icons/master/app/assets/images/payment_icons/diners_club.svg' className='interbankNetworkLogo' /> : <></>}

                    {(solution.features.interbankNetworks.discover) ? <img src='https://raw.githubusercontent.com/activemerchant/payment_icons/master/app/assets/images/payment_icons/discover.svg' className='interbankNetworkLogo' /> : <></>}
                  </p>
                  <a className='btn btn-outline-primary' href={solution.url}>Maggiori informazioni</a>
                </div>
              </div>
            </div>
          )
        }

      </div>
    </div >
  );
}

export default App;
