interface solutionFeatureInterbankNetworks {
  pagobancomat?: boolean
  mastercard?: boolean,
  maestro?: boolean,
  visa?: boolean,
  vpay?: boolean,
  american_express?: boolean,
  jcb?: boolean,
  unionpay?: boolean,
  discover?: boolean,
  diners_club?: boolean,
}

interface SolutionFeatures {
  wifi: boolean
  sim: boolean
  bluetooth: boolean
  interbankNetworks: solutionFeatureInterbankNetworks
}

export type Solution = {
  name: string
  firstYearCost: number
  yearlyCost: number
  description?: string
  features: SolutionFeatures
  image: string
  url: string
}

const interbankNetworks = {
  nexi: {
    pagobancomat: true,
    mastercard: true,
    maestro: true,
    visa: true,
    vpay: true,
    american_express: true,
    jcb: true,
    unionpay: true,
    discover: false,
    diners_club: false,
  },
  axerve: {
    pagobancomat: true,
    mastercard: true,
    maestro: true,
    visa: true,
    vpay: true,
    american_express: true,
    jcb: false,
    unionpay: false,
    discover: false,
    diners_club: false,
  },
  sumup: {
    pagobancomat: false,
    mastercard: true,
    maestro: true,
    visa: true,
    vpay: true,
    american_express: true,
    jcb: false,
    unionpay: true,
    discover: true,
    diners_club: true,
  },
  postepay: {
    pagobancomat: true,
    mastercard: true,
    maestro: true,
    visa: true,
    vpay: true,
    american_express: false,
    jcb: false,
    unionpay: false,
    discover: false,
    diners_club: false,
  },
  zettle: {
    pagobancomat: false,
    mastercard: true,
    maestro: true,
    visa: true,
    vpay: true,
    american_express: true,
    jcb: true,
    unionpay: true,
    discover: true,
    diners_club: true,
  },
  mypos: {
    pagobancomat: false,
    mastercard: true,
    maestro: true,
    visa: true,
    vpay: true,
    american_express: true,
    jcb: true,
    unionpay: true,
    discover: false,
    diners_club: false,
  }
}

const solutions: { (param: any): Solution }[] = [
  (data) => ({
    name: 'Nexi Mobile POS',
    firstYearCost: 15 + data.total * (1.89 / 100),
    yearlyCost: data.total * (1.89 / 100),

    description: `<ul><li>Costo iniziale: 15€</li>
    <li>Canone mensile: 0€</li>
    <li>Commissioni: 1,89% (0% su transazioni fino a 10€ fino al 31/12/2023)</li></ul>`,

    features: {
      wifi: false,
      sim: false,
      bluetooth: true,
      interbankNetworks: interbankNetworks.nexi
    },

    image: 'https://www.nexi.it/content/dam/mos/datecs/welcome-special/tile_L_mPOS_modulo%20pag%20prodotto_DATECS_desktop.jpg',
    url: 'https://www.nexi.it/shop/mobile-pos'
  }),
  (data) => ({
    name: 'Nexi SmartPOS Mini', // senza stampante (senza canone)
    firstYearCost: 149 + data.total * (1.89 / 100),
    yearlyCost: data.total * (1.89 / 100),

    description: `<ul><li>Costo iniziale: 149</li>
    <li>Canone mensile: 0€</li>
    <li>Commissioni: 1,89% (0% su transazioni fino a 10€ fino al 31/12/2023)</li></ul>`,

    features: {
      wifi: true,
      sim: true,
      bluetooth: false,
      interbankNetworks: interbankNetworks.nexi
    },

    image: 'https://www.nexi.it/shop/cart/pub/media/catalog/product/cache/012e8c7815ace18c4269ab5fde23cb27/s/m/smartposmini_1.jpeg',
    url: 'https://www.nexi.it/shop/smartpos-mini-pos2'
  }),

  (data) => ({
    name: 'Nexi SmartPOS Mini (Italia + Special)', // con stampante (canone)
    firstYearCost: 99 + 14.50 * 12 + data.total * (0.79 / 100),
    yearlyCost: 29 * 12 + data.total * (0.79 / 100),

    description: `<ul><li>Costo iniziale: 99€</li>
    <li>Canone mensile: 29€ (14,50€ i primi 12 mesi)</li>
    <li>Commissioni con "Italia + Special": 0,79% (0% su transazioni fino a 10€ fino al 31/12/2023)</li>
    <li>Commissioni con "Classic + Special": 0,95% su Mastercard/Visa, 0,55% su PagoBANCOMAT (0% su transazioni fino a 10€ fino al 31/12/2023)</li>
    </ul>`,

    features: {
      wifi: true,
      sim: true,
      bluetooth: false,
      interbankNetworks: interbankNetworks.nexi
    },

    image: 'https://www.nexi.it/shop/cart/pub/media/catalog/product/cache/012e8c7815ace18c4269ab5fde23cb27/c/o/conf_smartpos_mini_stampante_8.jpeg',
    url: 'https://www.nexi.it/shop/smartpos-mini-pos'
  }),
  (data) => ({
    name: 'Nexi SmartPOS Mini (Nexi Start)', // con stampante (canone)
    firstYearCost: 99 + 14.50 * 12 + Math.max(0, data.total - 1000) * (1.20 / 100),
    yearlyCost: 29 * 12 + Math.max(0, data.total - 1000) * (1.20 / 100),

    description: `<ul><li>Costo iniziale: 99€</li>
    <li>Canone mensile: 29€ (14,50€ i primi 12 mesi)</li>
    <li>Commissioni: 0% sui primi 1000€ in transazioni ogni mese, poi 1,20% (0% su transazioni fino a 10€ fino al 31/12/2023)</li>
    </ul>`,

    features: {
      wifi: true,
      sim: true,
      bluetooth: false,
      interbankNetworks: interbankNetworks.nexi
    },

    image: 'https://www.nexi.it/shop/cart/pub/media/catalog/product/cache/012e8c7815ace18c4269ab5fde23cb27/c/o/conf_smartpos_mini_stampante_8.jpeg',
    url: 'https://www.nexi.it/shop/offerta-start-pos-cordless'
  }),
  (data) => ({
    name: 'Nexi SmartPOS (Wi-Fi)',
    firstYearCost: 79 + 29 * 12 + data.total * (0.79 / 100),
    yearlyCost: 29 * 12 + data.total * (0.79 / 100),

    description: `<ul><li>Costo iniziale: 79€</li>
    <li>Canone mensile: 29€ (14,50€ i primi 12 mesi)</li>
    <li>Commissioni con Italia+: 0,79% (0% su transazioni fino a 10€ fino al 31/12/2023)</li>
    <li>Commissioni con Classic+: 0,95% su Mastercard/Visa, 0,55% su PagoBANCOMAT (0% su transazioni fino a 10€ fino al 31/12/2023)</li>
    </ul>`,

    features: {
      wifi: true,
      sim: false,
      bluetooth: false,
      interbankNetworks: interbankNetworks.nexi
    },

    image: 'https://notizie.tiscali.it/export/shared/agencies/media/18/07/17/Clipboard.jpg_997313609.jpg',
    url: 'https://www.nexi.it/shop/smartpos-pos'
  }),
  (data) => ({
    name: 'Nexi SmartPOS (Wi-Fi) (Nexi Start)',
    firstYearCost: 79 + 14.50 * 12 + Math.max(0, data.total - 1000) * (1.20 / 100),
    yearlyCost: 29 * 12 + Math.max(0, data.total - 1000) * (1.20 / 100),

    description: `<ul><li>Costo iniziale: 79€</li>
    <li>Canone mensile: 29€ (14,50€ i primi 12 mesi)</li>
    <li>Commissioni: 0% sui primi 1000€ in transazioni ogni mese, poi 1,20% (0% su transazioni fino a 10€ fino al 31/12/2023)</li>
    </ul>`,

    features: {
      wifi: true,
      sim: false,
      bluetooth: false,
      interbankNetworks: interbankNetworks.nexi
    },

    image: 'https://notizie.tiscali.it/export/shared/agencies/media/18/07/17/Clipboard.jpg_997313609.jpg',
    url: 'https://www.nexi.it/shop/offerta-start-pos-cordless'
  }),
  (data) => ({
    name: 'Nexi SmartPOS (Wi-Fi + 4G)',
    firstYearCost: 99 + 39 * 12 + data.total * (0.79 / 100),
    yearlyCost: 39 * 12 + data.total * (0.79 / 100),

    description: `<ul><li>Costo iniziale: 99€</li>
    <li>Canone mensile: 39€ (19,50€ i primi 12 mesi)</li>
    <li>Commissioni con Italia+: 0,79% (0% su transazioni fino a 10€ fino al 31/12/2023)</li>
    <li>Commissioni con Classic+: 0,95% su Mastercard/Visa, 0,55% su PagoBANCOMAT (0% su transazioni fino a 10€ fino al 31/12/2023)</li>
    </ul>`,

    features: {
      wifi: true,
      sim: true,
      bluetooth: false,
      interbankNetworks: interbankNetworks.nexi
    },

    image: 'https://notizie.tiscali.it/export/shared/agencies/media/18/07/17/Clipboard.jpg_997313609.jpg',
    url: 'https://www.nexi.it/shop/smartpos-pos'
  }),
  (data) => ({
    name: 'Nexi POS Portatile (Nexi Start)',
    firstYearCost: 99 + 15 * 12 + Math.max(0, data.total - 1000) * (1.20 / 100),
    yearlyCost: 15 * 12 + Math.max(0, data.total - 1000) * (1.20 / 100),

    description: `<ul><li>Costo iniziale: 99€</li>
    <li>Canone mensile: 15€</li>
    <li>Commissioni: 0% sui primi 1000€ in transazioni ogni mese, poi 1,20% (0% su transazioni fino a 10€ fino al 31/12/2023)</li>
    </ul>`,

    features: {
      wifi: false,
      sim: true,
      bluetooth: false,
      interbankNetworks: interbankNetworks.nexi
    },

    image: 'https://www.nexi.it/shop/cart/pub/media/catalog/product/cache/012e8c7815ace18c4269ab5fde23cb27/p/o/pos-portatile_3_1.jpg',
    url: 'https://www.nexi.it/shop/smartpos-pos'
  }),


  (data) => ({
    name: 'Postepay MPOS',
    firstYearCost: 29 + data.total * (1.8 / 100),
    yearlyCost: data.total * (1.8 / 100),

    description: `<ul><li>Costo iniziale: 29€</li>
    <li>Canone mensile: 0€</li>
    <li>Commissioni: 1,50% per carte Postepay e 1,80% per PagoBancomat, VISA e Mastercard</li></ul>`,

    features: {
      wifi: false,
      sim: false,
      bluetooth: true,
      interbankNetworks: interbankNetworks.postepay
    },

    image: 'https://dpsyidonm2c49.cloudfront.net/uploads/article_image/image/12886/optimized_52759720-2b3e-4036-a5a4-e604109939f6.jpg',
    url: 'https://business.poste.it/professionisti-imprese/prodotti/servizio-mpos-postepay.html'
  }),


  // SERVE CONTO BNP
  // (data) => ({
  //   name: 'Axepta Hi-POS (Start)',
  //   firstYearCost: data.total * (1.25 / 100) + (data.total < 6000 ? 79 : 0),
  //   yearlyCost: data.total * (1.25 / 100) + (data.total < 6000 ? 79 : 0),

  //   features: {
  //     wifi: false,
  //     sim: false,
  //     bluetooth: true,
  //     interbankNetworks: {
  //       pagobancomat: true,
  //       mastercard: true,
  //       maestro: true,
  //       visa: true,
  //       vpay: true,
  //     }
  //   },

  //   image: 'https://www.axepta.it/wp-content/uploads/2021/06/hipos1.png'
  // }),
  // (data) => ({
  //   name: 'Axepta Hi-POS (Pro)',
  //   firstYearCost: 9.90 * 12 + data.total * (0.99 / 100),
  //   yearlyCost: 9.90 * 12 + data.total * (0.99 / 100),

  //   features: {
  //     wifi: false,
  //     sim: false,
  //     bluetooth: true,
  //     interbankNetworks: {
  //       pagobancomat: true,
  //       mastercard: true,
  //       maestro: true,
  //       visa: true,
  //       vpay: true,
  //     }
  //   },

  //   image: 'https://www.axepta.it/wp-content/uploads/2021/06/hipos1.png'
  // }),
  // (data) => ({
  //   name: 'Axepta Hi-POS (Tech)',
  //   firstYearCost: 19.90 * 12 + data.total * (0.99 / 100),
  //   yearlyCost: 19.90 * 12 + data.total * (0.99 / 100),

  //   features: {
  //     wifi: false,
  //     sim: false,
  //     bluetooth: true,
  //     interbankNetworks: {
  //       pagobancomat: true,
  //       mastercard: true,
  //       maestro: true,
  //       visa: true,
  //       vpay: true,
  //       unionpay: true
  //     }
  //   },

  //   image: 'https://www.axepta.it/wp-content/uploads/2021/06/hipos1.png'
  // }),


  (data) => ({
    name: 'Zettle lettore di carte',
    firstYearCost: 29 + data.total * (2.75 / 100),
    yearlyCost: data.total * (2.75 / 100),

    description: `<ul><li>Costo iniziale: 29€</li>
    <li>Canone mensile: 0€</li>
    <li>Commissioni: 2,75% (può arrivare all'1% in base al transato mensile)</li></ul>`,

    features: {
      wifi: false,
      sim: false,
      bluetooth: true,
      interbankNetworks: interbankNetworks.zettle
    },

    image: 'https://img2.storyblok.com/1440x1329/smart/filters:format(webp):quality(93)/f/86596/1440x1329/06cbbd4281/reader-2-front_white_zettle_it_2x.png',
    url: 'https://www.zettle.com/it/lettore-di-carte'
  }),


  (data) => {
    const monthlyCanone = (data.total <= 10000) ? 17 : 22
    const yearlyCost =
      (data.total <= 30000) ? monthlyCanone * 12 :
        monthlyCanone * 12 + (data.total - 30000) * (1 / 100)
    return {
      name: 'Axerve POS Easy a canone',
      firstYearCost: yearlyCost - monthlyCanone * 2, // promo 2 mesi gratis
      yearlyCost,

      description: `<ul><li>Costo iniziale: 0€</li>
      <li>Canone mensile: 17€ fino a 10.000€ di transato/anno, 22€ fino a 30.000€ di transato/anno</li>
      <li>Commissioni: 0% (1% sul transato oltre i 30.000€)</li></ul>`,

      features: {
        wifi: true,
        sim: true,
        bluetooth: false,
        interbankNetworks: interbankNetworks.axerve
      },

      image: 'https://www.axerve.com/images/onboarding/carousel/pos-1-shadow.png',
      url: 'https://www.axerve.com/punto-vendita/pos'
    }
  },
  (data) => ({
    name: 'Axerve POS Easy a commissioni',
    firstYearCost: 80 + data.total * (1 / 100),
    yearlyCost: data.total * (1 / 100),

    description: `<ul><li>Costo iniziale: 80€</li>
      <li>Canone mensile: 0€</li>
      <li>Commissioni: 1%</li></ul>`,

    features: {
      wifi: true,
      sim: true,
      bluetooth: false,
      interbankNetworks: interbankNetworks.axerve
    },

    image: 'https://www.axerve.com/images/onboarding/carousel/pos-1-shadow.png',
    url: 'https://www.axerve.com/punto-vendita/pos-easy-commissioni'
  }),

  (data) => ({
    name: 'Sumup Air',
    firstYearCost: 39.99 + data.total * (1.95 / 100),
    yearlyCost: data.total * (1.95 / 100),

    description: `<ul><li>Costo iniziale: 39,99€</li>
      <li>Canone mensile: 0€</li>
      <li>Commissioni: 1,95%</li></ul>`,

    features: {
      wifi: false,
      sim: false,
      bluetooth: true,
      interbankNetworks: interbankNetworks.sumup
    },

    image: 'https://images.ctfassets.net/txhaodyqr481/3IUttqvXWw6ok2WIEwEiqY/d1286f07235deffc0ce005879c6842a4/Air_Comparison.png',
    url: 'https://sumup.it/air-lettore-nfc/'
  }),
  (data) => ({
    name: 'SumUp 3G',
    firstYearCost: 129.99 + data.total * (1.95 / 100),
    yearlyCost: data.total * (1.95 / 100),

    description: `<ul><li>Costo iniziale: 129,99€</li>
      <li>Canone mensile: 0€</li>
      <li>Commissioni: 1,95%</li></ul>`,

    features: {
      wifi: false,
      sim: true,
      bluetooth: false,
      interbankNetworks: interbankNetworks.sumup
    },

    image: 'https://images.ctfassets.net/txhaodyqr481/1s8LPw5c4qvpoidNPCfH7Z/dea3c58b5ee94dbc80a8b48fb80a332b/SIGNUP_3G_Printer-bundle.png',
    url: 'https://sumup.it/3g-pos-portatile/'
  }),
  (data) => ({
    name: 'SumUp Solo',
    firstYearCost: 79.98 + data.total * (1.95 / 100),
    yearlyCost: data.total * (1.95 / 100),

    description: `<ul><li>Costo iniziale: 79,98€</li>
      <li>Canone mensile: 0€</li>
      <li>Commissioni: 1,95%</li></ul>`,

    features: {
      wifi: true,
      sim: true,
      bluetooth: false,
      interbankNetworks: interbankNetworks.sumup
    },

    image: 'https://images.ctfassets.net/txhaodyqr481/5Zk1aKzaIiIB1EC84UMm30/6c9e367bf2765f4ea57651ed9a08ccc3/SOLO_PRODUCT_COMPARISON_IMAGE_V3.png',
    url: 'https://sumup.it/solo-pos-mobile/'
  }),

  (data) => {
    const scontrinoMedio = 5;
    const yearlyCost = data.total * (1.2 / 100) + (data.total / scontrinoMedio) * 0.05;
    return {
      name: 'myPOS Go',
      firstYearCost: 53.68 + yearlyCost,
      yearlyCost,

      description: `<ul><li>Costo iniziale: 53,68€ (39€ + IVA e spedizione)</li>
      <li>Canone mensile: 0€</li>
      <li>Commissioni: 0,05€ + 1,20%</li></ul>`,

      features: {
        wifi: false,
        sim: true,
        bluetooth: false,
        interbankNetworks: interbankNetworks.mypos
      },

      image: 'https://www.mypos.com/themes/mypos/assets/img/go-visa-italy-mar-2022/go-visa-italy-campaign.webp',
      url: 'https://www.mypos.com/it/acquisto-pos-mobile/mypos-go'
    }
  },
  (data) => {
    const scontrinoMedio = 5;
    const yearlyCost = data.total * (1.2 / 100) + (data.total / scontrinoMedio) * 0.05;
    return {
      name: 'myPOS Carbon',
      firstYearCost: 273.28 + yearlyCost,
      yearlyCost,

      description: `<ul><li>Costo iniziale: 273,28€ (139€ + IVA e spedizione)</li>
      <li>Canone mensile: 0€</li>
      <li>Commissioni: 0,05€ + 1,20%</li></ul>`,

      features: {
        wifi: true,
        sim: true,
        bluetooth: true,
        interbankNetworks: interbankNetworks.mypos
      },

      image: 'https://www.mypos.com/shop-resources/themes/mypos/assets/img/devices/N86/top/default/mypos-carbon.webp',
      url: 'https://www.mypos.com/it/acquisto-pos-mobile/carbon'
    }
  },
]

export const getPOSSolutions = function (data: any) {
  return solutions.map(solution => solution(data))
}
